/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import './global.css'
import './layout.css'

const Layout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="site--layout">
      <Helmet
        title={site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      />
      <header>
        <h1>structre</h1>
      </header>
      <main>{children}</main>
      <footer>
        <p>
          &copy; 2021 Founded by{' '}
          <a
            href="https://twitter.com/theorynest"
            target="_blank"
            rel="noopener noreferrer"
          >
            Janice Kyla Nascimento Smith
          </a>{' '}
          and{' '}
          <a
            href="https://twitter.com/alvincrespo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Alvin Crespo
          </a>
          .
        </p>
        <p>
          Designed and Developed with{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>{' '}
          in Massachusetts.
        </p>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
